import React from 'react'
import { graphql } from 'gatsby'
import ContentBlock from '../components/modules/ContentBlock'
import styled from '@emotion/styled'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import { MaxWidthContainer } from '../styles/mixins'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import Hero from '../components/modules/Hero'
import ContactForm from '../components/contact-form'

export const query = graphql`
  query ContactPageQuery {
    page: sanityPage(_id: { regex: "/(drafts.|)contact/" }) {
      id
      header
      _rawBody
			_rawExtendedContent
			mainImage {
				crop {
					_key
					_type
					top
					bottom
					left
					right
				}
				hotspot {
					_key
					_type
					x
					y
					height
					width
				}
				asset {
					_id
				}
				alt
			}
			cta {
				title
				href
				target
			}
    }
  }
`

const ContactPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const page = data.page

  if (!page) {
    throw new Error(
      'Missing "Contact" page data. Open the studio at http://localhost:3333 and add "Contact" page data and restart the development server.'
    )
  }

  return (
    <Layout>
      <SEO title={page.title ? page.title : 'Contact'} />
      <Container>
        <Hero
          page={page}
        />
        <ThisContainer maxW="800px">
					<ContentBlock content={page._rawExtendedContent} />
				</ThisContainer>
      </Container>
    </Layout>
  )
}
ContactPage.defaultProps = {
  data: {
    page: {
      title: 'No title'
    }
  }
}
export default ContactPage

const ThisContainer = styled(MaxWidthContainer)`
	min-height:calc(35.5vh - 60px);
	display:flex;
	align-items:center;
`