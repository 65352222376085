import React from 'react'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import _ from 'lodash'
import { MaxWidthContainer } from '../styles/mixins'
import styled from '@emotion/styled'
import theme from '../styles/theme'
import arrow from '../images/white-arrow.png'
import { useSpring, animated, to } from 'react-spring'
import { useHover } from 'react-use-gesture'

export default function ContactForm() {
  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitSuccessful, isSubmitting }
  } = useForm()
  const onSubmit = async data => {
    function encode(data) {
      return Object.keys(data)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&')
    }
    try {
      const url = '/'
      const requestConfig = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }

      await axios.post(url, encode({ 'form-name': 'contact-form', ...data }), requestConfig)
    } catch (e) {
      console.error(e)
    }
  }

  const buttonRef = React.useRef(null)
  const [{ scale }, api] = useSpring(() => ({
    scale: 1,
    config: { mass: 5, tension: 350, friction: 40 }
  }))

  const bind = useHover(({ hovering }) => {
    if (hovering) {
      api.start({ scale: 1.05 })
    } else {
      api.start({ scale: 1 })
    }
  })

  return (
    <MaxWidthContainer className="max-width-container" maxW="800px">
      <Form
        onSubmit={handleSubmit(onSubmit)}
        data-netlify="true"
        method="post"
        name="contact-form"
        netlify-honeypot="bot-field"
      >
        <input type="hidden" name="form-name" value="contact-form" />
        {!isSubmitSuccessful && (
          <div className="wrapper">
            <input
              type="text"
              placeholder="name*"
              name="name"
              className="name"
              ref={register({ required: true, maxLength: 80 })}
            />
            <input
              type="email"
              placeholder="email*"
              name="email"
              className="email"
              ref={register({
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Please enter a valid email address'
                }
              })}
            />
          </div>
        )}
        {!isSubmitSuccessful && (
          <textarea
            type="message"
            name="message"
            placeholder="message*"
            className="message"
            rows="7"
            ref={register({ required: true, maxLength: 300 })}
          />
        )}

        {!_.isEmpty(errors) && <span className="error-message">Please fill out all fields.</span>}

        {!isSubmitSuccessful && (
          <animated.button
            ref={buttonRef}
            type="submit"
            style={{ scale: to([scale], s => s) }}
            {...bind()}
          >
            <img src={arrow} alt="arrow" />
            <span>Submit</span>
          </animated.button>
        )}

        {isSubmitting && <span>Submitting...</span>}

        {isSubmitSuccessful && <span>Thank you for signing up!</span>}
      </Form>
    </MaxWidthContainer>
  )
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-bottom: 50px;
  margin-top: 50px;
  input,
  textarea {
    border: 2px solid ${theme.colors.aqua};
    padding: 8px;
    margin-bottom: 24px;
    font-family: 'Montserrat Bold';
    color: ${theme.colors.grey};
    ::placeholder {
      color: ${theme.colors.grey};
    }
    &:focus {
      outline: none;
      border-color: ${theme.colors.ocean};
    }
  }
  .wrapper {
    display: flex;
    width: 100%;
    input {
      &.name {
        width: 35%;
        margin-right: 5%;
      }
      &.email {
        width: 60%;
      }
    }
  }
  textarea {
    width: calc(100% - 16px);
  }
  button {
    cursor: pointer;
    position: relative;
    display: flex;
    background-color: ${theme.colors.aqua};
    align-items: center;
    outline: none;
    border: none;
    color: ${theme.colors.white};
    padding: 8px 32px;
    font-size: 18px;
    font-family: 'Montserrat Bold';
    text-transform: uppercase;
    transition: box-shadow 0.5s, opacity 0.5s;
    will-change: transform;
    *:focus {
      outline: 0 !important;
    }
    &:hover {
      /* box-shadow: 5px 5px 0px 0px ${theme.colors.mud}; */
      box-shadow: 5px 5px 0px 0px rgba(61,111,127,0.95);
    }
    img {
      position: relative;
      display: inline-block;
      margin-right: 10px;
      width: 15px;
      height: auto;
    }
  }
  .error-message {
    margin-bottom: 20px;
  }
  @media (${theme.breakpoints.mobile}) {
    .wrapper {
      flex-direction: column;
      input {
        &.name,
        &.email {
          width: calc(100% - 20px);
        }
      }
    }

    input, select, textarea {
      border-radius: 0;
      -webkit-border-radius: 0;
      -webkit-appearance: none;
      font-size: 16px;
    }
  }
`
